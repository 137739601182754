// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
//   apiKey: "AIzaSyAih3UvXoMkde5Tt43qfOATmI0vDRWvzcc",
//   authDomain: "training-1006f.firebaseapp.com",
//   projectId: "training-1006f",
//   storageBucket: "training-1006f.appspot.com",
//   messagingSenderId: "646889910902",
//   appId: "1:646889910902:web:5171276cabceb645552557"

  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_MESSAGING_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// export const auth = getAuth();

export const db = getFirestore(app);

