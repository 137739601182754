import './App.css';
import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Login from "./Login"
import Admin from "./Admin"
import Events from "./Events"
import Add from "./Add"
import { db } from "./firebase"
import { collection, getDocs, addDoc, orderBy, query } from "firebase/firestore"

function App() {

  
    return (
      <div>
        
        <BrowserRouter>
          <Routes>

            <Route path="/" element={<Events />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/add" element={<Add />} />
              
          </Routes>
        </BrowserRouter>
      
        
      </div>
    )

  
}

export default App;
