import './App.css';
import React from "react"
import { useState } from "react";
import { db } from "./firebase"
import { collection, addDoc, query } from "firebase/firestore"

function Add() {

  const [newDate, setNewDate] = useState("");
  const [newClass, setNewClass] = useState("");
  const [newLocation, setNewLocation] = useState("");
  const [newDistance, setNewDistance] = useState("");
  const [newAvgPace, setNewAvgPace] = useState("");
  const [newAvgMovingPace, setNewAvgMovingPace] = useState("");
  const [newBestPace, setNewBestPace] = useState("");
  const [newAvgSpeed, setNewAvgSpeed] = useState("");
  const [newAvgMovingSpeed, setNewAvgMovingSpeed] = useState("");
  const [newMaxSpeed, setNewMaxSpeed] = useState("");
  const [newTotalTime, setNewTotalTime] = useState("");
  const [newMovingTime, setNewAvgMovingTime] = useState("");
  const [newElapsedTime, setNewElapsedTime] = useState("");
  const [newAvgHeartRate, setNewAvgHeartRate] = useState("");
  const [newMaxHeartRate, setNewMaxHeartRate] = useState("");  


  // const [trainingEvents, setTrainingEvents] = useState([]);
  const eventsCollectionRef = collection(db, "events")
  // const q = query(eventsCollectionRef, orderBy("eventDate"));

  const createTrainingEvent = async () => {
    
    await addDoc(eventsCollectionRef,
      {
        eventDate: newDate,
        class: newClass,
        location: newLocation,
        distance: newDistance,
        avgPace: newAvgPace,
        avgMovingPace: newAvgMovingPace,
        bestPace: newBestPace,
        avgSpeed: newAvgSpeed,
        avgMovingSpeed: newAvgMovingSpeed,
        maxSpeed: newMaxSpeed,
        totalTime: newTotalTime,
        movingTime: newMovingTime,
        elapsedTime: newElapsedTime,
        avgHeartRate: newAvgHeartRate,
        maxHeartRate: newMaxHeartRate

      }
      )
      document.getElementById("message").textContent = "Record added successfully"
      window.location.reload(true);
  }
  

    return (
      <div className='add-data'>
      
        <div className='add-data-fields'>
          <h1 className='title'>Daytona Clash Duathlon</h1>
          <h1 className='title'>Add Training Events</h1>
          <input type='text' placeholder='event date' onChange={(event) => {setNewDate(event.target.value)}} />
          <input type='text' placeholder='event type' onChange={(event) => {setNewClass(event.target.value)}} />
          <input type='text' placeholder='location' onChange={(event) => {setNewLocation(event.target.value)}} />
          <input type='text' placeholder='distance' onChange={(event) => {setNewDistance(event.target.value)}} />
          <input type='text' placeholder='avg pace' onChange={(event) => {setNewAvgPace(event.target.value)}} />
          <input type='text' placeholder='avg moving pace' onChange={(event) => {setNewAvgMovingPace(event.target.value)}} />
          <input type='text' placeholder='best pace' onChange={(event) => {setNewBestPace(event.target.value)}} />
          <input type='text' placeholder='avg speed' onChange={(event) => {setNewAvgSpeed(event.target.value)}} />
          <input type='text' placeholder='avg moving speed' onChange={(event) => {setNewAvgMovingSpeed(event.target.value)}} />
          <input type='text' placeholder='max speed' onChange={(event) => {setNewMaxSpeed(event.target.value)}} />
          <input type='text' placeholder='total time' onChange={(event) => {setNewTotalTime(event.target.value)}} />
          <input type='text' placeholder='moving time' onChange={(event) => {setNewAvgMovingTime(event.target.value)}} />
          <input type='text' placeholder='elapsed time' onChange={(event) => {setNewElapsedTime(event.target.value)}} />
          <input type='text' placeholder='avg heart rate' onChange={(event) => {setNewAvgHeartRate(event.target.value)}} />
          <input type='text' placeholder='max heart rate' onChange={(event) => {setNewMaxHeartRate(event.target.value)}} />
          <button onClick={createTrainingEvent}>Add new training event</button>
          <p id='message'></p>
        </div>
      </div>

    );

  
}

export default Add;
