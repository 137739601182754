import './App.css';
import React from "react"
import { useState, useEffect } from "react";
import { db } from "./firebase"
import { collection, getDocs, addDoc, orderBy, query } from "firebase/firestore"

function App() {

  const [trainingEvents, setTrainingEvents] = useState([]);
  const eventsCollectionRef = collection(db, "events")
  const q = query(eventsCollectionRef, orderBy("eventDate", "desc"));

  useEffect(() => {

      const getTrainingEvents = async () => {

        // const data = await getDocs(eventsCollectionRef);
        const data = await getDocs(q);

        setTrainingEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      };

      getTrainingEvents()
  
  }, [])

  
    return (
      <div>
      
        <h1 className='title'>Daytona Clash Duathlon Training Log</h1>

        {trainingEvents.map((trainingEvent) => {

          return (
            <div className='data'>

              <h3>{trainingEvent.eventDate} {trainingEvent.class} | {trainingEvent.location} {trainingEvent.distance}</h3>

              <div className='stats'>
              
                <div className='stat'>
                  <h4 className='stat-heading'>PACE</h4>
                  <p><span className='heading'>Avg Pace:</span> {trainingEvent.avgPace}</p>
                  <p><span className='heading'>Avg Moving Pace:</span> {trainingEvent.avgMovingPace}</p>
                  <p><span className='heading'>Best Pace:</span> {trainingEvent.bestPace}</p>
                </div>

                <div className='stat'>
                  <h4 className='stat-heading'>SPEED</h4>
                  <p><span className='heading'>Avg Speed:</span> {trainingEvent.avgSpeed}</p>
                  <p><span className='heading'>Avg Moving Speed:</span> {trainingEvent.avgMovingSpeed}</p>
                  <p><span className='heading'>Max Speed:</span> {trainingEvent.maxSpeed}</p>
                </div>

                <div className='stat'>
                  <h4 className='stat-heading'>TIMING</h4>
                  <p><span className='heading'>Total Time:</span> {trainingEvent.totalTime}</p>
                  <p><span className='heading'>Moving Time:</span> {trainingEvent.movingTime}</p>
                  <p><span className='heading'>Elapsed Time:</span> {trainingEvent.elapsedTime}</p>
                </div>

                <div className='stat'>
                  <h4 className='stat-heading'>HEART RATE</h4>
                  <p><span className='heading'>Average Heart Rate:</span> {trainingEvent.avgHeartRate}</p>
                  <p><span className='heading'>Max Heart Rate:</span> {trainingEvent.maxHeartRate}</p>
                </div>
              </div>
            </div>
          )

        })}
      
      </div>
    );

  
}

export default App;
