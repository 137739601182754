import './App.css';
// import { useState, useEffect } from "react";
// import { BrowserRouter, Routes, Route } from "react-router-dom"
// import { db } from "./firebase"
// import { collection, getDocs, addDoc, orderBy, query } from "firebase/firestore"

function Login() {

    return (
      <div className='login'>
        
        <h1>Training Events Admin Login</h1>

        <div className='login-fields'>
          <label htmlFor='email'>Email</label>
          <input type='email' id="email" />
        </div>

        <div className='login-fields'>
          <label htmlFor='password'>Password</label>
          <input type='password' id="password" />
        </div>
      
      </div>
    )

  
}

export default Login;
