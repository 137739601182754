import './App.css';
import { useState, useEffect } from "react";


import { db } from "./firebase"
import { collection, getDocs, orderBy, query, doc, deleteDoc } from "firebase/firestore"

function Admin() {

  const [trainingEvents, setTrainingEvents] = useState([]);
  const eventsCollectionRef = collection(db, "events")
  const q = query(eventsCollectionRef, orderBy("eventDate", "desc"));


  useEffect(() => {

      const getTrainingEvents = async () => {

        // const data = await getDocs(eventsCollectionRef);
        const data = await getDocs(q);

        setTrainingEvents(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      };

      getTrainingEvents()
  }, [])

  const deleteRecord = async (id) => {

    const recordDoc = doc(db, "events", id);
    await deleteDoc(recordDoc)
    window.location.reload(true);
  }

    return (
      <div>
        


        <h1 className='title'>Daytona Clash Duathlon Training Log - Admin</h1>
        {trainingEvents.map((trainingEvent) => {

          return (
            <div className='data'>
            
              <h3><button onClick={() => {deleteRecord(trainingEvent.id)}}>delete</button> | {trainingEvent.eventDate} {trainingEvent.class} | {trainingEvent.location} {trainingEvent.distance}</h3>

              
            </div>
          )

        })}
      
      </div>
    );

  
}

export default Admin;
